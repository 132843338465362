import {
  flow,
  onPatch,
  getRoot,
  getParentOfType,
  getPathParts,
  types as t,
  IDisposer,
  getSnapshot,
  IJsonPatch
} from "mobx-state-tree"
import debounce from "lodash.debounce"
import isEqual from "lodash.isequal"

import { findJsCodeKey } from "./key-data"
import Layer from "../layer"
import Layout from "../layout"
import Revision from "../revision"
import KeyStep from "./key-step"
import KeyHistory from "./key-history"
import { ERGO_GLOW_POSITIONS, PLANCK_LEFT_INDICES } from "./utils"

import { client } from "../../../api"
import { updateKey } from "../../../api/queries/layer"
import { keyHistory, createKeyHistory } from "../../../api/queries/history"
import Macro from "./macro"

import { MAX_KEY_INDICES } from "../../utils/combos"

// Array used to compose the list of available actions
// in the popover in edit mode

export type KeySteps = "tap" | "hold" | "doubleTap" | "tapHold"

const UPDATE_PATH_DIRTY = ["/tap", "/hold", "/doubleTap", "/tapHold"]

export type SerializedKey = {
  glowColor: string | null
  customLabel: string | null
  tappingTerm: number | null
  tap: IKeyStep | null
  hold: IKeyStep | null
  doubleTap: IKeyStep | null
  tapHold: IKeyStep | null
  icon: string | null
  emoji: string | null
}

type LayerTarget = {
  layer: number
  code: string
}

const ignorePersistence = [
  "/aboutPosition",
  "/glowColor",
  "/swapping",
  "/swapped",
  "/customLabelEditing",
  "/history",
  "/tappingTerm",
  "/editing",
  "/pristine"
]

const defaultToHoldActions = [
  "KC_LCTRL",
  "KC_RCTRL",
  "KC_LSHIFT",
  "KC_RSHIFT",
  "KC_LALT",
  "KC_RALT"
]

const Key = t
  .model("Key", {
    about: t.maybeNull(t.string),
    aboutPosition: t.maybeNull(t.number),
    glowColor: t.maybeNull(t.string),
    customLabel: t.maybeNull(t.string),
    icon: t.maybeNull(t.string),
    emoji: t.maybeNull(t.string),
    tap: t.maybeNull(KeyStep),
    hold: t.maybeNull(KeyStep),
    doubleTap: t.maybeNull(KeyStep),
    tapHold: t.maybeNull(KeyStep),
    tappingTerm: t.maybeNull(t.number),
    history: t.array(KeyHistory),
    detached: false,
    swapping: false,
    swapped: false,
    pristine: true,
    editing: false
  })
  .views((self) => ({
    // Views start
    get index(): number {
      const nodePath = getPathParts(self)
      const keyIdx = nodePath.pop()
      return parseInt(keyIdx!, 10)
    },
    get hasIcon(): boolean {
      return self.icon != null && self.icon.length > 0
    },
    get hasEmoji(): boolean {
      return self.emoji != null && self.emoji.length > 0
    },
    get isColorKey(): boolean {
      return this.keySteps.some((step) => step.isColorKey)
    },
    get annotation(): ITourStep | null {
      const revision = getParentOfType(self, Revision)
      if (!revision.tour) return null

      const step = revision.tour.steps.find((step: ITourStep) => {
        return (
          step.keyIndex == this.index &&
          step.layer?.position === revision.layer?.position
        )
      })
      return step || null
    },
    get availableSteps(): AvailableStep[] {
      const steps: AvailableStep[] = []
      if (!self.tap || (self.tap && self.tap.code === "KC_TRANSPARENT"))
        steps.push({ step: "tap", label: "tapped" })
      if (!self.hold) steps.push({ step: "hold", label: "held" })
      if (!self.doubleTap)
        steps.push({ step: "doubleTap", label: "double-tapped" })
      if (!self.tapHold)
        steps.push({
          step: "tapHold",
          label: "tapped and then held"
        })

      return steps
    },
    get isBlank(): boolean {
      if (this.isMacro) return false
      if (this.keySteps.length === 0) return true
      return !this.keySteps.some(
        (step: IKeyStep) => step.code !== "KC_TRANSPARENT"
      )
    },
    get isModTap(): boolean {
      if (
        this.isTapDance == false &&
        self.tap?.keyData.shifted == false &&
        self.tap?.keyData.category != "layer" &&
        self.hold?.isModifier
      )
        return true
      return false
    },
    get isLT(): boolean {
      if (
        this.isTapDance == false &&
        self.hold?.code == "MO" &&
        self.tap?.keyData.shifted == false &&
        self.tap?.hasModifiers == false
      ) {
        if (
          (self.hold?.code == "MO" ||
            self.hold?.code == "LOWER" ||
            self.hold?.code == "RAISE") &&
          self.tap?.keyData.shifted == false
        )
          return true
      }
      return false
    },
    get isConflictual(): boolean {
      return (
        this.keySteps.some((step: IKeyStep) => step && step.isConflictual) ||
        !!this.danceError
      )
    },
    // Checks for invalid dance combos
    get danceError(): string | null {
      if (this.isTapDance && self.hold && self.hold.isOpenMultiMod) {
        return "Multiple modifiers on hold won't work for this key assignment — this is a firmware limitation."
      }
      if (self.doubleTap && self.doubleTap.isOpenMultiMod) {
        return "Multiple modifiers on double tap won't work for this key assignment — this is a firmware limitation."
      }
      if (self.tapHold && self.tapHold.isOpenMultiMod) {
        return "Multiple modifiers on double tap and hold won't work for this key assignment — this is a firmware limitation."
      }
      if (self.tap && self.hold && (self.doubleTap || self.tapHold)) {
        if (
          self.hold.keyData.category == "modifier" &&
          self.hold.hasModifiers
        ) {
          return "This assignment is unfortunately not supported, remove the double tap and tap and hold action for that key."
        }
      }
      if (self.tap && self.hold) {
        if (
          (self.tap.code == "ALL_T" || self.tap.code == "MEH_T") &&
          (self.hold.code == "ALL_T" || self.hold.code == "MEH_T")
        ) {
          return "This assignment is unfortunately not supported, please remove your Hyper / Meh assignment on tap or hold."
        }
      }
      return null
    },
    get layerTargets(): LayerTarget[] {
      const targets: LayerTarget[] = []
      this.keySteps.forEach((step) => {
        if (step.layer !== null && step.isPreceding)
          targets.push({ layer: step.layer, code: step.code })
      })
      return targets
    },
    get precedingKey(): IKey | null {
      if (this.layerIdx === 0) return null
      if (self.detached) return null

      const { layers } = getParentOfType(self, Revision)
      const { position } = getParentOfType(self, Layer)

      const previousLayers = layers.filter(
        (layer: ILayer) => layer.position < this.layerIdx
      )

      let precedingKey: IKey | null = null
      previousLayers.some(({ keys }: { keys: IKey[] }) => {
        const previousKey = keys[this.index]
        if (!previousKey) return false

        const target = previousKey.layerTargets.find(
          (t: LayerTarget) => t.layer === position
        )
        if (target) {
          // Check if ambidextrous LT/MO/LM is activated and there's at least two LT/MO/LM
          // aiming to that layout.
          if (
            target.code === "MO" ||
            target.code === "LM" ||
            target.code === "TT"
          ) {
            const { config } = getParentOfType(self, Revision)
            if (config && config.ambidexLT === true) {
              return false
            }
          }
          // If the previous key is a tap dance with TO on tap, don't consider it as a preceding key
          precedingKey = previousKey
        }

        return false
      })
      return precedingKey
    },
    get isPreceding(): boolean {
      return this.keySteps.some((step: IKeyStep) => step && step.isPreceding)
    },
    get isPreceded(): boolean {
      return this.precedingKey !== null
    },
    get isTapDance(): boolean {
      /*
                    A key will result to a tap dance if:
                    - doubleTap or tapHold steps are defined
                    - if it's a dual function key with a shifted tap action
                    - if it's a dual function key with a modified tap action
                    - if it's a dual function key with hyper or Meh as a tap action
                  */
      if (self.doubleTap || self.tapHold) {
        return true
      }
      if (self.tap && self.hold) {
        if (self.tap.code == "ALL_T" || self.tap.code == "MEH_T") {
          return true
        }
        if (
          self.tap.keyData.category == "layer" &&
          (self.hold.code == "ALL_T" || self.hold.code == "MEH_T")
        ) {
          return true
        }
        if (self.tap.hasModifiers == true) {
          return true
        }
        if (self.tap.keyData.shifted == true) {
          return true
        }
      }
      return false
    },
    get preventOpenMod(): boolean {
      const {
        router: { keyTab }
      } = getRoot(self)
      if (
        keyTab == "hold" &&
        this.isTapDance &&
        self.hold &&
        self.hold.isModifier
      ) {
        return true
      }
      if (
        keyTab == "doubleTap" &&
        this.isTapDance &&
        self.doubleTap &&
        self.doubleTap.isModifier
      ) {
        return true
      }
      if (
        keyTab == "tapHold" &&
        this.isTapDance &&
        self.tapHold &&
        self.tapHold.isModifier
      ) {
        return true
      }
      return false
    },
    get precededMessage(): string {
      if (
        this.precedingKey &&
        this.precedingKey.keySteps.some((step: IKeyStep) => {
          return step.code == "TG" && step.layer == this.layerIdx
        })
      ) {
        return "This key has been disabled on this layer since it's needed to return you to your original layer."
      }
      return "This key has been disabled on this layer since you are using it as a momentary toggle to switch to the layer."
    },
    get isTransparent(): boolean {
      const { simpleView } = getParentOfType(self, Layout)
      if ((self.customLabel || self.icon || self.emoji) && simpleView)
        return false
      return this.isBlank && this.layerIdx > 0
    },
    get hasTransparentBinding(): boolean {
      const { layers } = getParentOfType(self, Revision)
      const previousKey = layers[0].keys[this.index]
      return !previousKey.isBlank
    },
    get keyUrl(): string {
      const {
        router: { route, layoutId, revisionId, layerIdx, geometry }
      } = getRoot(self)

      const url = `/${geometry}/layouts/${layoutId}/${revisionId}/${layerIdx}/${this.index}/`
      if (route === "embed") {
        return `/embed${url}`
      }
      return url
    },
    get keySteps(): IKeyStep[] {
      const steps = []
      if (self.tap && self.tap !== null) steps.push(self.tap)
      if (self.hold && self.hold !== null) steps.push(self.hold)
      if (self.doubleTap && self.doubleTap !== null) steps.push(self.doubleTap)
      if (self.tapHold && self.tapHold !== null) steps.push(self.tapHold)
      return steps
    },
    get layerIdx(): number {
      if (self.detached) {
        try {
          const { currentCombo } = getParentOfType(self, Revision)
          if (currentCombo) {
            return currentCombo.layerIdx
          }
        } catch (e) {
          return 0
        }
        return 0
      }
      const layer = getParentOfType(self, Layer)
      // Layer might not be available here.
      if(!layer) return 0
      return layer.position
    },
    get layerHashId(): string {
      const { hashId } = getParentOfType(self, Layer) as ILayer
      return hashId
    },
    get isLayerKey(): boolean {
      return this.keySteps.some((key: IKeyStep) => {
        return key.layer !== null
      })
    },
    get keyActions(): KeyAction[] {
      const actions: KeyAction[] = []

      const {
        router: { keyTab }
      } = getRoot(self)

      if (this.isMacro) {
        actions.push({
          step: "tap",
          link: "tapped",
          action: `${self.tap!.macro!.description}`
        })
        return actions
      }

      if (this.isTransparent) {
        const { layers } = getParentOfType(self, Revision)
        if (layers[0]) {
          return layers[0].keys[this.index].keyActions
        }
      }

      if ((self.tap && self.tap.code != "KC_TRANSPARENT") || keyTab == "tap") {
        actions.push({
          step: "tap",
          link: "tapped",
          action: (self.tap && self.tap.keyAction) || ""
        })
      }
      if ((self.hold && !this.isTapOnly) || keyTab == "hold") {
        actions.push({
          step: "hold",
          link: "held",
          action: (self.hold && self.hold.keyAction) || ""
        })
      }
      if ((self.doubleTap && !this.isTapOnly) || keyTab == "doubleTap")
        actions.push({
          step: "doubleTap",
          link: "double-tapped",
          action: (self.doubleTap && self.doubleTap.keyAction) || ""
        })
      if ((self.tapHold && !this.isTapOnly) || keyTab == "tapHold")
        actions.push({
          step: "tapHold",
          link: "tapped and held",
          action: (self.tapHold && self.tapHold.keyAction) || ""
        })

      return actions
    },
    get hasGlow(): boolean {
      const { geometry } = getParentOfType(self, Layout)
      const { model } = getParentOfType(self, Revision)
      if (geometry === "voyager") return true
      if (geometry === "moonlander") return true
      if (geometry === "halfmoon") return true
      if (geometry === "planck-ez" && model === "glow") return true
      if (geometry.includes("ergodox-ez") && model === "glow") {
        if (ERGO_GLOW_POSITIONS.indexOf(this.index) !== -1) return true
      }
      return false
    },
    get glow(): string | null {
      if (this.hasGlow) {
        if (self.glowColor) return self.glowColor
        const { color } = getParentOfType(self, Layer)
        if (color) {
          return color
        }
      }
      return null
    },
    get serialize(): SerializedKey {
      const that = getSnapshot(self)
      return {
        glowColor: that.glowColor,
        customLabel: that.customLabel,
        tappingTerm: that.tappingTerm,
        tap: that.tap,
        hold: that.hold,
        doubleTap: that.doubleTap,
        tapHold: that.tapHold,
        icon: that.icon,
        emoji: that.emoji
      }
    },
    get serializeActions() {
      return {
        tap: self.tap
          ? {
              code: self.tap.code,
              layer: self.tap.layer,
              modifiers: self.tap.modifiers
            }
          : null,
        hold: self.hold
          ? {
              code: self.hold.code,
              layer: self.hold.layer,
              modifiers: self.hold.modifiers
            }
          : null,
        doubleTap: self.doubleTap
          ? {
              code: self.doubleTap.code,
              layer: self.doubleTap.layer,
              modifiers: self.doubleTap.modifiers
            }
          : null,
        tapHold: self.tapHold
          ? {
              code: self.tapHold.code,
              layer: self.tapHold.layer,
              modifiers: self.tapHold.modifiers
            }
          : null
      }
    },
    get labels(): KeyLabels {
      if (this.isTransparent) {
        const { layers } = getParentOfType(self, Revision)
        const key = layers[0].keys[this.index]
        if (key !== this) {
          return key.labels
        }
      }

      if (this.isPreceded) {
        return this.precedingKey!.labels
      }

      const labels: KeyLabels = {
        top: null,
        bottom: null,
        icon: null,
        emoji: null
      }

      if (self.icon) {
        labels.icon = self.icon
      }

      if (self.emoji) {
        labels.emoji = self.emoji
      }

      const { simpleView } = getParentOfType(self, Layout)

      if (simpleView && self.customLabel) {
        labels.top = {
          label: self.customLabel,
          tag: null,
          glyph: null,
          layer: null,
          modifiers: null
        }
        return labels
      }

      let stepCount = 0
      this.keySteps.forEach((step) => {
        if (stepCount == 2) return
        if (!labels.top) {
          labels.top = step.keyLabel
          return
        }
        if (!labels.bottom) {
          labels.bottom = step.keyLabel
          return
        }
        stepCount++
      })
      return labels
    },
    get macroSteps() {
      if (!self.tap || self.tap.macro === null) {
        return {
          firstKey: false,
          secondKey: false,
          thirdKey: false,
          fourthKey: false,
          fifthKey: false
        }
      }
      const { keys } = self.tap.macro
      return {
        firstKey: keys.length > 0 && !keys[0].isEmpty,
        secondKey: keys.length > 1 && !keys[1].isEmpty,
        thirdKey: keys.length > 2 && !keys[2].isEmpty,
        fourthKey: keys.length > 3 && !keys[3].isEmpty,
        fifthKey: keys.length > 4 && !keys[4].isEmpty
      }
    },
    get hasHoldOnly(): boolean {
      return !!(self.hold && !self.tap && !self.doubleTap && !self.tapHold)
    },
    // returns true if a key has the same labels / icon in the same layer
    get hasDoubleOnSameLayer(): boolean {
      const { keys } = getParentOfType(self, Layer)
      return keys.some((key: IKey) => {
        if (this.index == key.index) return false
        return isEqual(this.labels, key.labels)
      })
    },
    get doubleOnSameLayerIndex(): number | null {
      let index = null
      const { keys } = getParentOfType(self, Layer)
      keys.some((key: IKey) => {
        if (this.index == key.index) return false
        if (isEqual(this.labels, key.labels)) {
          index = key.index
          return true
        }
        return false
      })

      return index
    },
    get isModifiable(): boolean {
      // if a key is part of a combo, its mapping can't be modified.
      if (this.isPartOfCombo) return false
      return true
    },
    get isPartOfCombo(): ICombo | null {
      let parentCombo: ICombo | null = null
      const { combos } = getParentOfType(self, Revision)

      if (combos) {
        combos.forEach((combo: ICombo) => {
          if (
            !parentCombo &&
            combo.layerIdx == this.layerIdx &&
            combo.keyIndices.includes(this.index)
          ) {
            parentCombo = combo
          }
        })
      }

      return parentCombo
    },
    get isComboPickable(): boolean {
      const { currentCombo } = getParentOfType(self, Revision)
      // Prevent keys to be picked when the combo has reached its maximum
      if (
        currentCombo?.keyIndices.length == MAX_KEY_INDICES &&
        !this.isPartOfCurrentCombo
      )
        return false

      // Prevent keys with multiple actions to be picked
      if (this.isTapDance) return false

      // Prevent transparent keys
      if (this.isTransparent) return false

      //LT keys can be picked
      if (this.isLT) return true

      //Mod taps keys can be picked
      if (this.isModTap) return true

      //Modifiers (hold actions) can be picked
      if (self.hold?.isModifier) return true

      // Prevent double keys with the same index already picked on the current combo
      const doubleIndex = this.doubleOnSameLayerIndex
      if (doubleIndex && currentCombo?.keyIndices.includes(doubleIndex))
        return false

      return self.tap?.keyData.comboPickable || false
    },
    get isPartOfCurrentCombo(): boolean {
      const { currentCombo } = getParentOfType(self, Revision)
      return currentCombo?.keyIndices.some((idx: number) => idx == this.index)
    },
    get isTapOnly(): boolean {
      if (self.tap) {
        const { tap, hold, doubleTap, tapHold } = self.tap.keyData
        if (
          tap == true &&
          hold == false &&
          doubleTap == false &&
          tapHold == false
        ) {
          return true
        }
      }
      return false
    },
    get isHoldOnly(): boolean {
      if (self.hold) {
        const { tap, hold, doubleTap, tapHold } = self.hold.keyData
        if (
          tap == false &&
          hold == true &&
          doubleTap == false &&
          tapHold == false
        ) {
          return true
        }
      }
      return false
    },
    // returns a list of keys that have the same tap, hold, doubleTap and tapHold actions
    get duplicates(): IKey[] {
      const dups: IKey[] = []
      const { layers } = getParentOfType(self, Revision)
      const {
        tap: thisTap,
        hold: thisHold,
        doubleTap: thisDoubleTap,
        tapHold: thisTapHold
      } = this.serializeActions
      layers.forEach((layer: ILayer) => {
        layer.keys.forEach((key: IKey) => {
          if (key.index != this.index || key.layerIdx != this.layerIdx) {
            const { tap, hold, doubleTap, tapHold } = key.serializeActions
            // do a shallow comparison of the tap, hold, doubleTap and tapHold actions
            if (
              isEqual(thisTap, tap) &&
              isEqual(thisHold, hold) &&
              isEqual(thisDoubleTap, doubleTap) &&
              isEqual(thisTapHold, tapHold)
            ) {
              dups.push(key)
            }
          }
        })
      })
      return dups
    },
    get uniqueActionWarning(): string {
      if (this.isTapOnly || this.isMacro) {
        return (
          "This key assignment is only for tapping, and does" +
          " not support hold actions."
        )
      }
      if (this.isHoldOnly) {
        return (
          "This key assignment is only for holding, and does" +
          " not support tap actions."
        )
      }
      return ""
    },
    get isLeft(): boolean {
      const {
        router: { geometry }
      } = getRoot(self)
      if (geometry === "halfmoon") return true
      if (geometry === "moonlander" && this.index < 36) return true
      if (geometry.includes("ergodox-ez") && this.index <= 37) return true
      if (geometry === "planck-ez" && PLANCK_LEFT_INDICES.includes(this.index))
        return true
      if (geometry === "voyager" && this.index <= 25) return true
      return false
    },
    get modifierLabels(): ActionLabel | null {
      const { simpleView } = getParentOfType(self, Layout)
      if (self.customLabel && simpleView) return null

      if (self.tap && self.tap.modifiers)
        return {
          label: self.tap.modifiers.description,
          glyph: null,
          layer: null
        }
      return null
    },
    get isMagic(): boolean {
      return !!(
        !this.isTapOnly &&
        !this.hasHoldOnly &&
        (self.hold || self.doubleTap || self.tapHold)
      )
    },
    get isModifier(): boolean {
      return this.keySteps.some((step: IKeyStep) => {
        return step.isModifier
      })
    },
    get isMacro(): boolean {
      return !!(self.tap && self.tap.macro)
    },
    get isDance(): boolean {
      return !!(this.keySteps.length > 2)
    },
    get isShine(): boolean {
      return !!(self.tap && self.tap.keyData!.category === "shine")
    },
    get ledColor(): string | null {
      if (this.isShine) {
        return self.tap!.color
      }
      return null
    },
    get isSelected(): boolean {
      const {
        router: { keyIdx }
      } = getRoot(self)
      if (!keyIdx) return false
      return keyIdx == this.index
    },
    get hasCustomLabel(): boolean {
      return !!self.customLabel
    },
    get selectedColor(): string {
      const { swatch } = getParentOfType(self, Revision)
      if (swatch) {
        return swatch.selectedColor
      }
      return ""
    },
    get isWebusbPairingKey(): boolean {
      return this.keySteps.some((step) => {
        return step.code == "WEBUSB_PAIR"
      })
    },
    get cssClass(): string {
      let className = "key"

      if (this.isConflictual) {
        className += " danger"
        if (this.isPreceded) {
          className += " disabled"
        }
      } else if (this.isPreceded) {
        className += " disabled"
      } else if (this.isMagic) {
        className += " magic"
      } else if (this.isModifier) {
        className += " modifier"
      } else if (this.isMacro) {
        className += " macro"
      } else if (this.isShine) {
        className += " shine"
      } else if (this.hasCustomLabel) {
        className += " custom"
      }

      if (this.isSelected) {
        className += " selected"
      }

      return className
    },
    get currentStep(): IKeyStep | null {
      const {
        router: { comboStep, keyTab }
      } = getRoot(self)
      const tab = comboStep == "trigger" ? "tap" : (keyTab as KeySteps)
      return self[tab]
    }
  }))
  .actions((self) => {
    let disposer: IDisposer

    // Actions start
    function updateCustomLabel(value: string) {
      self.customLabel = value || null
    }

    function updateIcon(value: string) {
      self.icon = value || null
    }

    function updateEmoji(value: string) {
      self.emoji = value || null
    }

    function restore(data: any, path: string) {
      if (path == "glowColor") {
        self.glowColor = data.glowColor
        return
      }

      // Restoring steps
      const val = data[path]
      if (val) {
        self[path] = KeyStep.create(val)
      } else {
        self[path] = null
      }
    }

    function setHistory() {}

    const updateTappingTerm = flow(function* updateTappingTerm(value: number) {
      const {
        layout: {
          revision: { defaultTappingTerm }
        }
      } = getRoot(self) as IStore
      if (value === defaultTappingTerm) {
        self.tappingTerm = null
      } else {
        self.tappingTerm = value
      }
      yield self.persistUpdate()
      const dupes = self.duplicates

      for (let i = 0; i < dupes.length; i += 1) {
        const key = dupes[i]
        if ((key.isLT || key.isModTap) && key.tappingTerm != self.tappingTerm) {
          yield key.updateTappingTerm(value)
        }
      }
    })

    function clearCustomLabel() {
      self.customLabel = null
      self.icon = null
      self.emoji = null
    }

    // Returns true if a key code is used in one of the key step.
    // Not techinically an action but computed properties
    // do not accept parameters
    function hasKeyCode(code: string): boolean {
      return self.keySteps.some((step: IKeyStep) => {
        return step.code == code
      })
    }

    function updateAbout(value: string) {
      if (!value) {
        self.aboutPosition = null
        self.about = null
      } else {
        if (self.aboutPosition === null) {
          const { tour } = getParentOfType(self, Revision)
          self.aboutPosition = tour.length
        }
        self.about = value
      }
    }

    function setAboutPosition(pos: number) {
      self.aboutPosition = pos
    }

    const setGlowColor = flow(function* setGlowColor(
      color: string | null,
      persist = true
    ) {
      /*
                  if (persist === true) {
                    setPreEditSnapshot()
                  }
                  */

      if (color == null) {
        self.glowColor = null
      } else {
        let Color = color.toUpperCase()
        if (Color === self.glowColor) {
          self.glowColor = null
        } else {
          self.glowColor = Color
        }
      }
      if (persist === true) {
        yield self.persistUpdate()
      }
    })

    function setTapActionWithJSCode(jsCode: string) {
      // A key part of a combo cannot be edited
      if (self.isPartOfCombo) return
      if (self.isHoldOnly) return
      const key = findJsCodeKey(jsCode)
      if (key) {
        clearEmptySteps()
        if (defaultToHoldActions.includes(key.code)) {
          self.hold = KeyStep.create({ code: key.code })
        } else {
          self.tap = KeyStep.create({ code: key.code })
        }
        if (self.annotation) {
          const { ui } = getRoot(self) as IStore
          ui.setTourStepWarning(true)
        }
      }
    }

    function updateStepWithCode(code: string) {
      const {
        router: { comboStep, keyTab, keyEditorTab }
      } = getRoot(self)

      if (keyEditorTab == "macro" || comboStep == "macro") {
        if (!self.isMacro) {
          self.tap = KeyStep.create({
            code: "KC_TRANSPARENT",
            macro: { keys: [{ code }] }
          })
        } else {
          self.tap!.macro!.setStep(code)
        }
      } else if (!keyEditorTab || comboStep == "trigger") {
        const tab: KeySteps =
          comboStep == "trigger" ? "tap" : (keyTab as KeySteps)

        let color = code === "RGB" ? "#550000" : null
        // Clear blank tap step
        if (self.tap && self.tap.code == "KC_TRANSPARENT") {
          clearStep("tap")
        }

        self[tab] = KeyStep.create({ code, color })
      }
      if (self.isTapOnly) {
        self.hold = null
        self.tapHold = null
        self.doubleTap = null
      }
      if (self.isHoldOnly) {
        self.tap = null
        self.tapHold = null
        self.doubleTap = null
      }
    }

    function setPristine(val: boolean) {
    // This seems to create a race condition with the debounce save function, disabling for now to see if it fixes the issue
    // The only thing it does is removing the warning messages on annotated keys.
    //self.pristine = val
    }

    function beforeDestroy() {
      disposer()
    }

    function selectSwapKey() {
      const { setSwapKey } = getParentOfType(self, Revision)
      self.swapped = false
      self.swapping = !self.swapping
      setSwapKey(self)
    }

    function setSwapped(bool: boolean) {
      self.swapped = bool
    }

    const afterCreate = flow(function* afterCreation() {
      /* eslint-disable no-param-reassign */
      // retrieve the key's index from its parent layer
      // and get the geometry data.
      // @ts-ignore self is required here, ts will complain
      disposer = onPatch(
        self,
        debounce((patch: IJsonPatch) => {
          // If the key model is detached (ie not used within a layer, for example combos) ignore peristence.
          if (self.detached) return
          if (ignorePersistence.includes(patch.path)) return
          if (patch.path.includes("/history")) return
          //@ts-ignore
          self.persistUpdate()

          // If any of the tap step is updated while editing, mark the key as dirty
          // in order to display a layout tour warning if required.
          const markDirty = UPDATE_PATH_DIRTY.some((step) =>
            patch.path.includes(step)
          )

          if (markDirty && self.editing) {
            self.setPristine(false)
          }
        }, 400)
      )
    })

    const persistUpdate: (patch: { path: string }) => Promise<void> = flow(
      function* persist(patch) {
        const { editable } = getParentOfType(self, Revision)
        // Ignore aboutPosition and glowColor patches
        // Reordering keys could lead too to many
        // requests, aboutPosition / glowColor updates
        // are handled at the layer level
        if (editable) {
          const { hashId } = getParentOfType(self, Layer) as ILayer
          yield client.mutate({
            mutation: updateKey,
            variables: {
              hashId,
              key: self.serialize,
              position: self.index
            }
          })
        }
      }
    )

    function setKeyColor(hex: string) {
      if (self.tap) self.tap.color = hex
    }

    function clearTourStep() {
      if (!self.annotation) return
      self.annotation.clear()
    }

    function initMacro() {
      if (!self.tap || (self.tap && !self.tap.macro)) clear()
      self.tap = KeyStep.create({
        code: "KC_TRANSPARENT",
        macro: {}
      })
    }

    function toggleMacroParam(field: string) {
      if (self.tap && !self.tap.macro) clearStep("tap")
      if (!self.tap) self.tap = KeyStep.create({ code: "KC_TRANSPARENT" })
      if (!self.tap.macro) self.tap.macro = Macro.create()
      if (field == "applyAlt") self.tap!.macro!.toggleApplyAlt()
      if (field == "endEnter") self.tap!.macro!.toggleEndEnter()
    }

    function clearStep(step: KeySteps) {
      // Clear the tapping term if the key is not a magic key anymore
      if (self.tappingTerm && !self.isMagic) self.tappingTerm = null
      self[step] = null
      if (self.keySteps.length == 0) self.customLabel = null
    }

    function clear() {
      self.tap = null
      self.hold = null
      self.doubleTap = null
      self.tapHold = null
      self.customLabel = null
    }

    function clearEmptySteps() {
      if (self.tap && self.tap.code == "KC_TRANSPARENT") {
        clearStep("tap")
      }
      if (self.hold && self.hold.code == "KC_TRANSPARENT") {
        clearStep("hold")
      }
      if (self.doubleTap && self.doubleTap.code == "KC_TRANSPARENT") {
        clearStep("doubleTap")
      }
      if (self.tapHold && self.tapHold.code == "KC_TRANSPARENT") {
        clearStep("tapHold")
      }
    }

    // History actions
    let preEditSnapshot: SerializedKey | null = null

    const getKeyHistory = flow(function* getKeyHistory() {
      return
      const { hashId } = getParentOfType(self, Layer)
      try {
        const { data } = yield client.query({
          query: keyHistory,
          fetchPolicy: "network-only",
          variables: {
            layerHashId: hashId,
            position: self.index
          }
        })

        self.history = data.getKeyHistory.map((entry: any) => {
          return {
            hashId: entry.hashId,
            before: entry.before,
            after: entry.after,
            position: entry.position,
            path: entry.path,
            undone: entry.undone,
            isMacro: entry.isMacro
          }
        })
      } catch (e) {
        console.error(e)
      }
    })

    function setPreEditSnapshot() {
      preEditSnapshot = self.serialize
    }

    function clearPreEditSnapshot() {
      preEditSnapshot = null
    }

    const commitToHistory: () => Promise<void> = flow(
      function* persistHistory() {
        return
        if (!isEqual(self.serialize, preEditSnapshot)) {
          const { hashId } = getParentOfType(self, Layer) as ILayer
          try {
            const { data } = yield client.mutate({
              mutation: createKeyHistory,
              variables: {
                layerHashId: hashId,
                position: self.index,
                before: preEditSnapshot,
                after: self.serialize
              }
            })
            if (data?.createKeyHistory) {
              self.history = data.createKeyHistory.map((entry: any) => {
                return {
                  hashId: entry.hashId,
                  before: entry.before,
                  after: entry.after,
                  position: entry.position,
                  path: entry.path,
                  undone: entry.undone,
                  isMacro: entry.isMacro
                }
              })
            }
          } catch (e) {
            console.error(e)
          }
        }
        clearPreEditSnapshot()
      }
    )

    function setEditing(val: boolean) {
      self.editing = val
    }

    // History actions end

    return {
      afterCreate,
      beforeDestroy,
      clear,
      clearCustomLabel,
      clearPreEditSnapshot,
      clearStep,
      clearTourStep,
      commitToHistory,
      getKeyHistory,
      hasKeyCode,
      initMacro,
      persistUpdate,
      restore,
      selectSwapKey,
      setAboutPosition,
      setEditing,
      setGlowColor,
      setHistory,
      setKeyColor,
      setPreEditSnapshot,
      setPristine,
      setSwapped,
      setTapActionWithJSCode,
      toggleMacroParam,
      updateAbout,
      updateCustomLabel,
      updateEmoji,
      updateIcon,
      updateStepWithCode,
      updateTappingTerm
    }
  })

export default Key
